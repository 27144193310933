// SearchResultsTable.tsx

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface SearchResultsTableProps {
  results: any[];
  onOrderClick: (orderId: string) => void;
}

const SearchResultsTable: React.FC<SearchResultsTableProps> = ({
  results,
  onOrderClick,
}) => {
  return (
    <TableContainer component={Paper} sx={{ mt: 4 }}>
      <Table sx={{ minWidth: 650 }} aria-label="search results table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Billing</TableCell>
            <TableCell>SKUs</TableCell>
            <TableCell>EHS Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((order) => (
            <TableRow
              key={order.oyl_id}
              style={{
                backgroundColor: order.oyl_status === "order_canceled" ? "#FFAAAAAA" :
                  (order.oyl_status === "tried_to_cancel" ? "#FFEE22AA" :
                    (order.ehs_status === "rejected" ? "#FF9900AA" : "#FFFFFF00"))
              }}
            >
              <TableCell>{new Date(order.created).toLocaleString()}</TableCell>
              <TableCell>
                <span
                  onClick={() => onOrderClick(order.order_num)}
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  {order.order_num}
                </span>
              </TableCell>
              <TableCell>
                {`${order.customer_first_name || ""} ${
                  order.customer_last_name || ""
                } ${order.customer_middle_name || ""}`}
              </TableCell>
              <TableCell>{order.oyl_id_billing}</TableCell>
              <TableCell>{order.oyl_skus?.join(", ") || ""}</TableCell>
              <TableCell>{order.ehs_status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SearchResultsTable;
