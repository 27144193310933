// File: src/App2.tsx

import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import "./style/portal.css";
import StatusBar from "./nav_bar/StatusBar";
import CustomerStatusBar from "./nav_bar/CustomerStatusBar";
import { useUser } from "@clerk/clerk-react";
import ViewDashboard from "./fulfillment/ViewDashboard";
import ViewOrder from "./fulfillment/ViewOrder";
import ViewSearch from "./fulfillment/ViewSearch";
import ViewCustomerDashboard from "./customer/ViewCustomerDashboard";
import ViewWelcome from "./ViewWelcome" // test222
import { lg } from "../shared/utils/oyl_log";

const CustomerRoute = () => {
  const { user, isSignedIn, isLoaded } = useUser();
  
  lg("Customer Route Render:");
  lg("isLoaded:", isLoaded);
  lg("isSignedIn:", isSignedIn);
  lg("User:", user?.id);

  if (!isLoaded) {
    lg("Clerk still loading...");
    return <div>Loading authentication...</div>;
  }

  if (!isSignedIn) {
    lg("User not signed in, redirecting to welcome page");
    return <Navigate to="/welcome" replace />;
  }

  lg("Rendering customer dashboard for user:", user?.id);
  
  return (
    <React.Suspense fallback={<div>Loading dashboard...</div>}>
      <div>
        <CustomerStatusBar />
        <ViewCustomerDashboard />
      </div>
    </React.Suspense>
  );
};

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Opaque white background
          borderRadius: "4px", // Optional: Add some border radius for a smoother look
        },
      },
    },
  },
});

// A component to check if the user has the admin role
function AdminRoute({ children }: { children: JSX.Element }) {
  const { user } = useUser();

  if (!user) return null; // Return nothing while loading

  const userRole = user.publicMetadata?.role;

  if (userRole !== "admin") {
    return <Navigate to="/customer" replace />; // Redirect if the user is not an admin
  }

  return children; // Render the children (protected component) if the user is an admin
}

function App2() {
  return (
    <Router>

      {/* Routes for different pages */}

      <Routes>

        {/* Base URL Route with Redirect Link */}
        <Route
          path="/"
          element={
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <h1>Welcome to OwnYourLabs</h1>
              <p>This .net page is not active. Please visit: <a href="https://ownyourlabs.com">OwnYourLabs.com</a></p>
            </div>
          }
        />

        <Route
          path="/welcome"
          element={
            <div>
              <SignedIn>
                <Navigate to="/fulfillment" />
              </SignedIn>
              <ViewWelcome />
            </div>
          }
        />

        <Route
          path="/login"
          element={
            <div>
              <SignedOut>
                <Navigate to="/welcome" />
              </SignedOut>
              <SignedIn>
                <Navigate to="/fulfillment" />
              </SignedIn>
            </div>
          }
        />


        {/* customer portal routes */}

        {/* customer dashboard */}
        <Route
          path="/customer"
          element={
            <div>
            <SignedOut>
              <Navigate to="/welcome" />
            </SignedOut>
            <SignedIn>
              <CustomerRoute />
            </SignedIn>
            </div>
          }
        />

        {/* customer order */}
        {/* <ViewOrder **** PLACEHOLDER!! TODO: REPLACE WITH CUSTOMER ORDER VIEW! /> */}
        {/* <Route
          path="/order-customer"
          element={
            <div>
              <SignedOut>
                <Navigate to="/welcome" />
              </SignedOut>
              <SignedIn>
                <StatusBar />
                {(() => {
                  return null; // This will satisfy the need for a valid JSX node
                })()}
                  <ViewOrder />
              </SignedIn>
            </div>
          }
        /> */}


        {/* fulfillment portal routes */}
        <Route
          path="/fulfillment"
          element={
            <div>
              <SignedOut>
                <Navigate to="/welcome" />
              </SignedOut>
              <SignedIn>
                <StatusBar />
                {(() => {
                  //lg("SignedIn ViewDashboard reached!");
                  return null; // This will satisfy the need for a valid JSX node
                })()}
                <ThemeProvider theme={theme}>
                  <AdminRoute>
                    {/* <ViewDashboard /> */}
                    <ViewDashboard />
                  </AdminRoute>
                </ThemeProvider>
              </SignedIn>
            </div>
          }
        />

        <Route
          path="/order"
          element={
            <div>
              <SignedOut>
                <Navigate to="/welcome" />
              </SignedOut>
              <SignedIn>
                <StatusBar />
                {(() => {
                  //lg("SignedIn ViewOrder reached!");
                  return null; // This will satisfy the need for a valid JSX node
                })()}
                <AdminRoute>
                  {/* <ViewOrder /> */}
                  <ViewOrder />
                </AdminRoute>
              </SignedIn>
            </div>
          }
        />

        <Route
          path="/search"
          element={
            <div>
              <SignedOut>
                <Navigate to="/welcome" />
              </SignedOut>
              <SignedIn>
                <StatusBar />
                {(() => {
                  return null; // This will satisfy the need for a valid JSX node
                })()}
                <AdminRoute>
                  <ViewSearch />
                </AdminRoute>
              </SignedIn>
            </div>
          }
        />

        {/* Catch-all route for non-existing paths (no StatusBar) */}
        <Route
          path="*"
          element={
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <h1>404 - Page Not Found</h1>
              <p>This page does not exist. Please visit: <a href="https://ownyourlabs.com">OwnYourLabs.com</a></p>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App2;
