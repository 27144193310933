import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { lg } from "../../shared/utils/oyl_log";

const ViewDashboard: React.FC = () => {
  const [message, setMessage] = useState("");
  const [orders, setOrders] = useState<any[]>([]);

  const navigate = useNavigate();

  const fetchRecentOrders = async () => {
    lg("fetchRecentOrders");
    try {
      let response = await fetch(`/api/all-recent-orders`);
      if (!response.ok) {
        lg(response.statusText);
        throw new Error(`Error: ${response.statusText}`);
      }
      let data = await response.json();
      lg("Response for request of all orders:");
      lg(data);
      setOrders(data);
    } catch (error) {
      setMessage("Error fetching orders");
      console.error("Error fetching order details:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchRecentOrders();
  }, []);

  const clickedOrder = (orderId: string) => {
    lg(`Order ${orderId} clicked!`);
    navigate(`/order?orderId=${orderId}`); // Navigate to /order?orderId=xxx
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="recent orders table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Billing</TableCell>
            <TableCell>SKUs</TableCell>
            <TableCell>EHS Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow
              key={order.oyl_id}
              style={{
                backgroundColor: order.oyl_status === "order_canceled" ? "#FFAAAAAA" :
                  (order.oyl_status === "tried_to_cancel" ? "#FFEE22AA" :
                    (order.ehs_status === "rejected" ? "#FF9900AA" : "#FFFFFF00"))
              }}
            >
              <TableCell>{new Date(order.created).toLocaleString()}</TableCell>
              <TableCell>
                <span
                  onClick={() => clickedOrder(order.order_num)}
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  {order.order_num}
                </span>
              </TableCell>
              <TableCell>
                {`${order.customer.first_name} ${order.customer.last_name} ${
                  order.customer.middle_name || ""
                }`}
              </TableCell>
              <TableCell>{order.oyl_id_billing}</TableCell>
              <TableCell>{order.oyl_skus.join(", ")}</TableCell>
              <TableCell>{order.ehs_status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewDashboard;
