// ViewSearch.tsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, Button, Box, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs"; // Import Dayjs type
import CustomerFormSection from "./CustomerFormSection";
import BillingFormSection from "./BillingFormSection";
import SearchResultsTable from "./SearchResultsTable";
import theme from "../style/theme";
import ViewSearch_fetch from "./ViewSearch_fetch";

const ViewSearch = () => {
  const navigate = useNavigate();
  const {
    searchFields,
    setSearchFields,
    results,
    loading,
    error,
    handleSearch,
  } = ViewSearch_fetch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchFields((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (field: string, newValue: Dayjs | null) => {
    setSearchFields((prev: any) => ({ ...prev, [field]: newValue }));
  };

  const handleOrderClick = (orderId: string) => {
    navigate(`/order?orderId=${orderId}`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Grid container spacing={1} sx={{ padding: "10px" }}>
          <CustomerFormSection
            formData={searchFields}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            loading={loading}
          />
          <BillingFormSection
            formData={searchFields}
            handleChange={handleChange}
            loading={loading}
          />
          <Box sx={{ marginTop: "20px" }}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Box>
          {results.length > 0 && (
            <SearchResultsTable
              results={results}
              onOrderClick={handleOrderClick}
            />
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Grid>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default ViewSearch;
