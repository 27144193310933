import React, { useState } from 'react';
import { useClerk } from '@clerk/clerk-react';
import { Button, Container, Box, Typography, CircularProgress } from '@mui/material';
import bannerImage from './media/banner_smiling2.jpg';

const ViewWelcome: React.FC = () => {
  const { openSignIn } = useClerk(); // Access the Clerk instance for opening the sign-in modal
  const [loading, setLoading] = useState(false);
  const LOAD_TIMEOUT_DURATION = 3000; // 3 seconds

  const handleSignIn = async () => {
    setLoading(true);
  
    try {
      // Open Clerk's sign-in modal with explicit redirect URLs
      await openSignIn({
        afterSignInUrl: '/welcome', // Redirect here after successful sign-in
        afterSignUpUrl: '/welcome', // Redirect here after successful sign-up
      });
    } catch (error) {
      console.error('Error opening Clerk sign-in modal:', error);
    } finally {
      // Reset loading state
      setTimeout(() => {
        setLoading(false);
      }, LOAD_TIMEOUT_DURATION);
    }
  };

  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
      {/* Banner Image */}
      <Box
        component="img"
        src={bannerImage}
        alt="Welcome Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          mb: 4,
        }}
      />

      {/* Welcome Text */}
      <Typography variant="h4" gutterBottom>
        Welcome to the Own Your Labs Portal!
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        Please sign in or create an account to continue.
      </Typography>

      {/* Login/Create Account Button with Loading State */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSignIn}
        disabled={loading} // Disable button during loading
        endIcon={loading ? <CircularProgress size={20} /> : null} // Show loading spinner
      >
        {loading ? 'Loading...' : 'Login / Create Account'}
      </Button>
    </Container>
  );
};

export default ViewWelcome;
