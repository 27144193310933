// SuperSearchService.tsx

import dayjs from "dayjs";

export type AllowedSuperSearchKeys = {
  oylWoocommerceId: string;
  customerFirstName: string;
  customerLastName: string;
  customerMiddleName?: string;
  customerBirthDate: string;
  customerEmail: string;
  customerPhone: string;
  customerAddress1: string;
  customerAddress2?: string;
  customerCity: string;
  customerState: string;
  customerZip: string;
  billingFirstName: string;
  billingLastName: string;
  billingMiddleName?: string;
  billingBirthDate: string;
  billingEmail: string;
  billingPhone: string;
  billingAddress1: string;
  billingAddress2?: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  fromDate: string;
  toDate: string;
  limit: string | number;
};

interface SuperUpdateMetaData {
  user_is_admin: boolean;
  user_id: number | string | null;
  oyl_id_customer: string;
  oyl_id_billing: string;
  oyl_id_order: string;
  edit_note: string;
}

type AllowedSuperUpdateKeys = {
  customerAddress1?: string;
  customerAddress2?: string;
  customerBirthDate?: string;
  customerCity?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerGender?: string;
  customerLastName?: string;
  customerMiddleName?: string;
  customerPhone?: string;
  customerState?: string;
  customerZip?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  billingCity?: string;
  billingEmail?: string;
  billingFirstName?: string;
  billingLastName?: string;
  billingMiddleName?: string;
  billingPhone?: string;
  billingState?: string;
  billingZip?: string;
  oyl_skus_named?: string[];
  orderNote?: string;
  orderHowDidYou?: string;
};

export type SuperSearchResponse = {
  // Match the structure of your backend response
  oyl_id: string | number;
  oyl_id_customer: string | number;
  order_num: string | number;
  customer_first_name: string;
  customer_last_name: string;
  customer_middle_name?: string;
  customer_birth_date: string | number | dayjs.Dayjs | Date | null | undefined;
  customer_email: string;
  customer_phone: string;
  customer_address_line: string;
  customer_address_line2?: string;
  customer_address_city: string;
  customer_address_state: string;
  customer_address_zip: string;
  billing_first_name?: string;
  billing_last_name?: string;
  billing_middle_name?: string;
  billing_email?: string;
  billing_phone?: string;
  billing_address_line?: string;
  billing_address_line2?: string;
  billing_address_city?: string;
  billing_address_state?: string;
  billing_address_zip?: string;
  oyl_skus: string[];
  orderId?: string | number; //exactly same as order_num; TODO: deprecate
  timeline?: Response; //maybe temporary stopgap; fixing compiler type-based error
  // ... other fields
}[];

type SuperUpdateResponse = {
  message: string;
};

export class SuperSearchService {
  private baseUrl: string;

  constructor(baseUrl: string = '') {
    this.baseUrl = baseUrl;
  }

  /**
   * Performs a multi-table search across customers, orders, and billings tables
   * @param searchParams - Object containing search parameters
   * @returns Promise with the search results from multiple tables
   */
  async executeSuperSearch(searchParams: Partial<AllowedSuperSearchKeys>): Promise<SuperSearchResponse> {
    try {
      // Convert search params to URL query string, mapping oylWoocommerceId to the expected query param
      const queryParams = new URLSearchParams();
      Object.entries(searchParams)
        .filter(([_, value]) => value !== undefined && value !== '')
        .forEach(([key, value]) => {
          // Map the camelCase parameter name to the expected query parameter
          const paramName = key === 'oylWoocommerceId' ? 'orderId' : key;
          queryParams.append(paramName, value.toString());
        });

      const response = await fetch(`${this.baseUrl}/api/super-search?${queryParams}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to perform super search');
      }

      return await response.json();
    } catch (error) {
      console.error('SuperSearch error:', error);
      throw error;
    }
  }

  /**
   * Executes a multi-table update across customers, orders, and/or billings tables
   * @param metaData - Metadata about the update including IDs and user information
   * @param beforeState - Previous state of the data across tables
   * @param afterState - New state of the data across tables
   * @returns Promise with the update response
   */
  async executeSuperUpdate(
    metaData: SuperUpdateMetaData,
    beforeState: Partial<AllowedSuperUpdateKeys>,
    afterState: Partial<AllowedSuperUpdateKeys>
  ): Promise<SuperUpdateResponse> {
    try {
      // Filter out undefined values from before and after states
      const before_changes = Object.fromEntries(
        Object.entries(beforeState).filter(([_, v]) => v !== undefined)
      );
      
      const after_changes = Object.fromEntries(
        Object.entries(afterState).filter(([_, v]) => v !== undefined)
      );

      const payload = {
        meta: metaData,
        before_changes,
        after_changes
      };

      const response = await fetch(`${this.baseUrl}/api/super-update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to execute super update');
      }

      return await response.json();
    } catch (error) {
      console.error('SuperUpdate error:', error);
      throw error;
    }
  }

  /**
   * Helper method to fetch a single order by WooCommerce ID
   * @param oylWoocommerceId - The WooCommerce ID of the order to fetch
   * @returns Promise with the order data
   */
  async getOrderByWooCommerceId(oylWoocommerceId: string): Promise<SuperSearchResponse[0] | null> {
    try {
      const results = await this.executeSuperSearch({
        oylWoocommerceId,
        limit: 1
      });

      return results[0] || null;
    } catch (error) {
      console.error('GetSuperSearchByWooCommerceId error:', error);
      throw error;
    }
  }
}

export default SuperSearchService;