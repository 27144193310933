// ViewOrder_digest.tsx

import { dateFromDBStringToPrettySimpleString } from './DateFunctions';
import { isolateNewArrayMembers } from "../../shared/utils/dataTools";

export const OYL_ORDER_CREATION = 'oyl_order_creation';
export const IN_HOUSE_USER_UPDATE = 'fulfillment_portal_user_edit';
export const IN_HOUSE_ORDER_NOTE = 'fulfillment_team_order_note';
export const IN_HOUSE_ORDER_CANCELED = 'fulfillment_portal_cancel_order';
export const FAILED_CANCEL_ATTEMPT = 'failed_attempt_to_cancel_order';
export const EHS_CONTACT_LOG = 'ehs_contact_log';
export const EHS_ORDER_STATUS_UPDATE = 'ehs_order_status_update';
export const CUSTOMER_INFO_UPDATE = 'customer_portal_user_edit';

// Takes an Array of timeline logs (rows from v3_timeline corresponding to the
//  oyl_id_order for the current order) and returns an Array of logDisplayData
//  that the ViewOrder knows how to display as a visual timeline.
export function getTimelineDisplayData(timeline:any[]):any[] {
  // lg(' -- invoked timelineDisplayData');
  if (!timeline || timeline.length < 1) {
    return [];
  }
  const displayArray = [];
  for (let i = 0; i < timeline.length; ++i) {
    const log = timeline[i];
    const logDisplayData = {
      time: dateFromDBStringToPrettySimpleString(log.created), // time of logged event
      name: getTimelineType(log),
      details: [] as string[], // things that happened during the timeline event
      background: "#ffffff00"
    };

    switch(log.type) {
      case OYL_ORDER_CREATION:
        break;

      case EHS_CONTACT_LOG:
        if (!log.value) {
          logDisplayData.details.push("Contact Log: no additional information was recorded");
          break;
        }
        const logData = (typeof log.value === 'string') ? JSON.parse(log.value) : log.value;
        let typeTokens = logData.type.split('Log');
        if (!typeTokens || typeTokens.length < 1) {
          logDisplayData.details.push("EHS Contact Log - Error: failed to parse timeline info from database");
          break;
        }
        let contactLog = "EHS " + typeTokens[0] + " Contact Log";
        if (logData.notes && logData.notes.length > 0) {
          contactLog += ' - Note: ' + logData.notes;
        }
        logDisplayData.details.push(contactLog);
        break;

      case IN_HOUSE_USER_UPDATE:
        if (!log.value) {
          break;
        }
        const edit_note = log.value.edit_note;
        if (edit_note && edit_note.length > 0 && edit_note !== "none") {
          logDisplayData.details.push('User ' + log.value.user_id + ' added a note: ' + edit_note);
        }
        if (log.value.customer_changes_after) {
          const cca = log.value.customer_changes_after;
          const ccb = log.value.customer_changes_before;
          for (const ccKey in cca) {
            if (ccb && ccb[ccKey]) {
              logDisplayData.details.push(' - Changed customer ' + getShortNameForOrderDataField(ccKey) +
                ' from "' + ccb[ccKey] + '" to "' + cca[ccKey] + '"');
            } else {
              logDisplayData.details.push(' - Changed customer ' + getShortNameForOrderDataField(ccKey) +
                ' to "' + cca[ccKey] + '"');
            }
          }
        }
        if (log.value.billing_changes_after) {
          const bca = log.value.billing_changes_after;
          const bcb = log.value.billing_changes_before;
          for (const bcKey in bca) {
            if (bcb && bcb[bcKey]) {
              logDisplayData.details.push(' - Changed billing ' + getShortNameForOrderDataField(bcKey) +
                ' from "' + bcb[bcKey] + '" to "' + bca[bcKey] + '"');
            } else {
              logDisplayData.details.push(' - Changed billing ' + getShortNameForOrderDataField(bcKey) +
                ' to "' + bca[bcKey] + '"');
            }
          }
        }
        if (log.value.order_changes_after) {
          const oca = log.value.order_changes_after;
          const ocb = log.value.order_changes_before;
          for (const ocKey in oca) {
            if (ocKey === "oyl_skus_named" && ocb[ocKey]) {
              const skusBefore = (typeof ocb[ocKey] === 'string') ? JSON.parse(ocb[ocKey]) : ocb[ocKey];
              const skusAfter = (typeof oca[ocKey] === 'string') ? JSON.parse(oca[ocKey]) : oca[ocKey];
              let deleted = isolateNewArrayMembers(skusAfter, skusBefore);
              let added = isolateNewArrayMembers(skusBefore, skusAfter);
              let j = 0;
              for (j = 0; j < deleted.length; ++j) {
                logDisplayData.details.push(' - Removed SKU ' + deleted[j] + ' from order');
              }
              for (j = 0; j < added.length; ++j) {
                logDisplayData.details.push(' - Added SKU ' + added[j] + ' to order');
              }
            } else if (ocb && ocb[ocKey]) {
              logDisplayData.details.push(' - Changed order ' + getShortNameForOrderDataField(ocKey) +
                ' from "' + ocb[ocKey] + '" to "' + oca[ocKey] + '"');
            } else {
              logDisplayData.details.push(' - Changed order ' + getShortNameForOrderDataField(ocKey) +
                ' to "' + oca[ocKey] + '"');
            }
          }
        }
        break;

      case IN_HOUSE_ORDER_NOTE:
        logDisplayData.details.push(log.value.note);
        break;

      case IN_HOUSE_ORDER_CANCELED:
        if (log.value.note) {
          logDisplayData.details.push('Note: ' + log.value.note);
        }
        logDisplayData.background = "#FFAAAAAA";
        break;

      case FAILED_CANCEL_ATTEMPT:
        if (log.value.note) {
          logDisplayData.details.push('Note: ' + log.value.note);
        }
        logDisplayData.background = "#FFEE22AA";
        break;

      case EHS_ORDER_STATUS_UPDATE:
        if (log && log.value && log.value.ehs_status === "rejected") {
          logDisplayData.background = "#FF9900AA";
        }
        break;

      case CUSTOMER_INFO_UPDATE:
        if (!log.value) {
          break;
        }
        if (log.value.customer_changes_after) {
          const cca = log.value.customer_changes_after;
          const ccb = log.value.customer_changes_before;
          for (const ccKey in cca) {
            if (ccb && ccb[ccKey]) {
              logDisplayData.details.push(' - Changed customer ' + getShortNameForOrderDataField(ccKey) +
                ' from "' + ccb[ccKey] + '" to "' + cca[ccKey] + '"');
            } else {
              logDisplayData.details.push(' - Changed customer ' + getShortNameForOrderDataField(ccKey) +
                ' to "' + cca[ccKey] + '"');
            }
          }
        }
        if (log.value.billing_changes_after) {
          const bca = log.value.billing_changes_after;
          const bcb = log.value.billing_changes_before;
          for (const bcKey in bca) {
            if (bcb && bcb[bcKey]) {
              logDisplayData.details.push(' - Changed billing ' + getShortNameForOrderDataField(bcKey) +
                ' from "' + bcb[bcKey] + '" to "' + bca[bcKey] + '"');
            } else {
              logDisplayData.details.push(' - Changed billing ' + getShortNameForOrderDataField(bcKey) +
                ' to "' + bca[bcKey] + '"');
            }
          }
        }
        if (log.value.order_changes_after) {
          const oca = log.value.order_changes_after;
          const ocb = log.value.order_changes_before;
          for (const ocKey in oca) {
            if (ocKey === "oyl_skus_named" && ocb[ocKey]) {
              const skusBefore = (typeof ocb[ocKey] === 'string') ? JSON.parse(ocb[ocKey]) : ocb[ocKey];
              const skusAfter = (typeof oca[ocKey] === 'string') ? JSON.parse(oca[ocKey]) : oca[ocKey];
              let deleted = isolateNewArrayMembers(skusAfter, skusBefore);
              let added = isolateNewArrayMembers(skusBefore, skusAfter);
              let j = 0;
              for (j = 0; j < deleted.length; ++j) {
                logDisplayData.details.push(' - Removed SKU ' + deleted[j] + ' from order');
              }
              for (j = 0; j < added.length; ++j) {
                logDisplayData.details.push(' - Added SKU ' + added[j] + ' to order');
              }
            } else if (ocb && ocb[ocKey]) {
              logDisplayData.details.push(' - Changed order ' + getShortNameForOrderDataField(ocKey) +
                ' from "' + ocb[ocKey] + '" to "' + oca[ocKey] + '"');
            } else {
              logDisplayData.details.push(' - Changed order ' + getShortNameForOrderDataField(ocKey) +
                ' to "' + oca[ocKey] + '"');
            }
          }
        }
        break;

      default:
        logDisplayData.details.push('Error: unrecognized timeline event "' + log.type + '"');
        break;
    }

    displayArray.push(logDisplayData);
  }
  return displayArray;
}

function getTimelineType(timelineLog:any):string {
  const logType = timelineLog ? timelineLog.type : null;
  const userId = (timelineLog && timelineLog.value && timelineLog.value.user_id) ?
    timelineLog.value.user_id : "-1";

  switch(logType) {
    case OYL_ORDER_CREATION:
      return "New order created from website";
    case IN_HOUSE_USER_UPDATE:
      return "A fulfillment team edit" + (userId ? " was made by " + userId : "");
    case EHS_CONTACT_LOG:
      return "Everly Health made contact";
    case IN_HOUSE_ORDER_NOTE:
      return "User " + (userId ? userId : "?unknown?") + " added this note:";
    case IN_HOUSE_ORDER_CANCELED:
      return "User " + (userId ? userId : "?unknown?") + " canceled the order";
    case FAILED_CANCEL_ATTEMPT:
      if (userId) {
        return "User " + userId + " attempted to cancel the order and was denied by the EHS server";
      }
      return "An attempt to cancel the order was denied by the EHS server";
    case EHS_ORDER_STATUS_UPDATE:
      let updateText = "Everly Health Services updated the order status to ";
      if (timelineLog && timelineLog.value && timelineLog.value.ehs_status) {
        updateText += timelineLog.value.ehs_status;
      } else {
        updateText += "??<Issue: EHS status not found>??";
      }
      return updateText;
    case CUSTOMER_INFO_UPDATE:
      if (Object.keys(timelineLog.value.customer_changes_after).length > 1) {
        return "Customer made changes to personal information";
      }
      return "Customer made a change to personal information";
    default:
      return "Unknown / Error";
  }
}

// input will be like "oyl_skus_named", "customerFirstName",
// "billingEmail", etc. -- see orderData fields in ViewOrder
export function getShortNameForOrderDataField(fieldName:string) {
  switch(fieldName) {
    case "customerFirstName":
      return "first name";
    case "customerLastName":
      return "last name";
    case "customerMiddleName":
      return "middle name";
    case "customerBirthDate":
      return "birth date";
    case "customerEmail":
      return "email";
    case "customerPhone":
      return "phone number";
    case "customerAddress1":
      return "address line 1";
    case "customerAddress2":
      return "address line 2";
    case "customerCity":
      return "address city";
    case "customerState":
      return "address state";
    case "customerZip":
      return "address zip code";
    case "customerGender":
      return "gender";

    case "billingFirstName":
      return "first name";
    case "billingLastName":
      return "last name";
    case "billingMiddleName":
      return "middle name";
    case "billingEmail":
      return "email";
    case "billingPhone":
      return "phone number";
    case "billingAddress1":
      return "address line 1";
    case "billingAddress2":
      return "address line 2";
    case "billingCity":
      return "address city";
    case "billingState":
      return "address state";
    case "billingZip":
      return "address zip code";
    case "oyl_skus_named":
      return "SKUs";
    case "orderNote":
      return "note";
    case "orderHowDidYou":
      return "how-did-you-hear-about-us info";
    case "orderCoupons":
      return "coupons";
    default:
      return "??";
  }
}

export function getChangeTypeForTable(tableName:string) {
  switch(tableName) {
    case 'v3_customers':
      return 'customer';
    case 'v3_orders':
      return 'order';
    case 'v3_billings':
      return 'billing';
    case 'v3_specials':
      return 'special';
    default:
      return 'unknown';
  }
}