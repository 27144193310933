
// skuCachePlain, when populated, is a JSON Object whose keys are
//  the OYL skus (as strings), and their values are the names of
//  the tests they represent, as fetched from the sku_id and

import { lg } from "../../shared/utils/oyl_log";

//  test_name columns of every row in the v3_library_skus table.
let skuCachePlain:any = null;

// skuCacheChips is an array of strings, with one string per each
//  sku in skuCachePlain, with format: "<sku_id> - <test_name>".
//  E.g., "455 - Thyroid Panel" as expected by ViewOrder's Chips.
let skuCacheChips:Array<string> | null = null;

export async function getSkuTableForChips() {
  if (skuCacheChips) {
    return skuCacheChips;
  }
  await getSkuTable();
  return skuCacheChips ? skuCacheChips : [];
}

export async function getSkuTablePlain() {
  if (skuCachePlain) {
    return skuCachePlain;
  }
  await getSkuTable();
  return skuCachePlain;
}

async function getSkuTable() {
  try {
    // Fetch the response
    const response = await fetch(`/api/oyl-sku-table`);
    
    // Check if the response is successful (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    // Parse the JSON body
    skuCachePlain = await response.json();

    // Convert to chip format
    skuCacheChips = [];
    for (const key in skuCachePlain) {
      skuCacheChips.push(`${key} - ${skuCachePlain[key]}`);
    }

  } catch (error) {
    console.error('OYLOrderService: Error fetching sku table from /api/oyl-sku-table');
    lg(error);
  }
}

