// ViewCustomerDashboard_fetch.tsx
import { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { CustomerData } from "./ViewCustomerDashboard";
import { lg } from "../../shared/utils/oyl_log";

const ViewCustomerDashboard_fetch = () => {
  const { user, isLoaded: clerkLoaded, isSignedIn } = useUser();
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [orderHistory, setOrderHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    lg("=== Hook State ===");
    lg("Clerk loaded:", clerkLoaded);
    lg("Is signed in:", isSignedIn);
    lg("User object:", user);
    lg("================");

    const fetchCustomerData = async () => {
      if (!clerkLoaded) {
        lg("Clerk not yet loaded, waiting...");
        return;
      }

      if (!isSignedIn) {
        lg("User not signed in");
        setError("Please sign in to view your dashboard");
        setLoading(false);
        return;
      }

      if (!user) {
        lg("No user object available");
        setError("User information not available");
        setLoading(false);
        return;
      }

      lg("Starting data fetch for user:", user.id);

      try {
        // First API call
        lg("Fetching customer data...");
        const customerResponse = await fetch(`/api/customer-by-clerk/${user.id}`);
        lg("Customer response status:", customerResponse.status);

        if (!customerResponse.ok) {
          const errorData = await customerResponse.json();
          lg("Customer fetch error data:", errorData);
          throw new Error(errorData.message || "Failed to fetch customer data");
        }

        const customerDetails = await customerResponse.json();
        lg("Received customer details:", customerDetails);
        setCustomerData({
          customerFirstName: customerDetails.first_name,
          customerLastName: customerDetails.last_name,
          customerMiddleName: customerDetails.middle_name,
          customerEmail: customerDetails.email,
          customerPhone: customerDetails.phone,
          customerBirthDate: customerDetails.birth_date,
          customerGender: customerDetails.gender,
          customerAddress1: customerDetails.address_line,
          customerAddress2: customerDetails.address_line2,
          customerCity: customerDetails.address_city,
          customerState: customerDetails.address_state,
          customerZip: customerDetails.address_zip,
          customerId: customerDetails.oyl_id
        });

        // Second API call
        lg("Fetching orders for customer ID:", customerDetails.oyl_id);
        const ordersResponse = await fetch(`/api/customer-orders/${customerDetails.oyl_id}`);
        lg("Orders response status:", ordersResponse.status);

        if (!ordersResponse.ok) {
          const errorData = await ordersResponse.json();
          lg("Orders fetch error data:", errorData);
          throw new Error(errorData.message || "Failed to fetch order history");
        }

        const orders = await ordersResponse.json();
        lg("Received orders:", orders);
        setOrderHistory(orders);

      } catch (err) {
        console.error("Error in data fetch:", err);
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        lg("Fetch process complete, setting loading to false");
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [clerkLoaded, isSignedIn, user]);

    // Log state changes
    useEffect(() => {
    lg("=== State Updates ===");
    lg("Customer Data:", customerData);
    lg("Order History:", orderHistory);
    lg("Loading:", loading);
    lg("Error:", error);
    lg("==================");
  }, [customerData, orderHistory, loading, error]);

  return {
    customerData,
    orderHistory,
    loading,
    error,
  };
};

export default ViewCustomerDashboard_fetch;
