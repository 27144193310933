import { useState, useEffect } from "react";
import dayjs from "dayjs";
import SuperSearchService from "../services/SuperSearchService";

const superSearchService = new SuperSearchService();

const ViewOrder_fetch = (oylWoocommerceId: string) => {
  const [orderData, setOrderData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const order = await superSearchService.getOrderByWooCommerceId(oylWoocommerceId);
        
        if (!order) {
          throw new Error("Order not found");
        }

        // Ensure woocommerce order ID is included
        order.orderId = order.order_num || oylWoocommerceId;

        // Process dates with dayjs if necessary
        if (order.customer_birth_date) {
          order.customer_birth_date = dayjs(order.customer_birth_date);
        }

        const timelineResp = await fetch(
          `/api/timeline-for-order/${order.oyl_id}`
        );
        const timeline = await timelineResp.json();

        order.timeline = timeline;
        setOrderData(order);

      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    if (oylWoocommerceId) {
      fetchOrderDetails();
    } else {
      setLoading(false);
    }
  }, [oylWoocommerceId]);

  return { orderData, loading, error };
};

export default ViewOrder_fetch;