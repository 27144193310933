import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Opaque white background
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem", // Adjust font size
        },
        input: {
          // padding: "4px 8px", // Adjust input padding
          // height: "1.5em", // Set a smaller height
          // lineHeight: "1.5em", // Ensure text is vertically centered
          // display: "flex", // Enable flexbox to center text
          // alignItems: "center", // Center items vertically
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem", // Adjust label font size
        },
      },
    },
  },
});

export default theme;
