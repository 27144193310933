// EHSServices.tsx
// Service-layer front-end functions for accessing EHS-related server routes.

import { lg } from "../../shared/utils/oyl_log";

export async function downloadPdfReq(ehsId:string) {
  const route = `/api/order-ehs-pdf-req/${ehsId}`;
  const filename = `requisition_${ehsId}.pdf`;
  const caller = `downloadPdfReq`;
  downloadPdf(route, filename, caller);
}

export async function downloadPdfResults(ehsId:string) {
  const route = `/api/order-ehs-pdf-results/${ehsId}`;
  const filename = `results_${ehsId}.pdf`;
  const caller = `downloadPdfResults`;
  downloadPdf(route, filename, caller);
}

// PRIVATE function for utilizing GET EHS endpoints from our API for downloading .pdfs
async function downloadPdf(route:string, filename:string, caller:string) {
  // Fetch the PDF
  const response = await fetch(route);
        
  if (response.ok) {
    // Convert the response to a blob
    const blob = await response.blob();
    
    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    const reqUrl = URL.createObjectURL(blob);
    downloadLink.href = reqUrl;
    downloadLink.download = filename; // Default file name
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    lg(' -- PDF download triggered from ' + caller);
  } else {
    console.error('downloadPdf from ' + caller + ': Failed to fetch PDF:', response.statusText);
  }
}