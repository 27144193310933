// OrderSection.tsx

import React, { useEffect, useState } from "react";
import { TextField, Typography, Chip, Autocomplete, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getSkuTableForChips } from "../services/OYLOrderService";
import FormSection, { FormField } from "./FormSection"; // Adjust the path if necessary

interface OrderSectionProps {
  formData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddChip: (chip: string) => void;
  handleDeleteChip: (chipToDelete: string) => void;
  chipData: string[];
  loading: boolean;
}

const orderFields: FormField[] = [
  { label: "Customer note for order", name: "orderNote" },
  { label: "How did you hear about us?", name: "orderHowDidYou" }
]

const OrderSection: React.FC<OrderSectionProps> = ({
  formData,
  handleChange,
  handleAddChip,
  handleDeleteChip,
  chipData,
  loading,
}) => {
  const [skuOptions, setSkuOptions] = useState<string[]>([]); // State for SKUs

  // Load SKUs asynchronously
  useEffect(() => {
    async function loadSkus() {
      try {
        const skus = await getSkuTableForChips();
        setSkuOptions(skus); // Update state with SKUs
      } catch (error) {
        console.error("Failed to load SKUs:", error);
      }
    }
    loadSkus();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <Grid container spacing={1} width={"100%"}>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          padding: "2px",
          position: "relative",
          width: "100%",
          backgroundColor: formData.oyl_status === "order_canceled" ? "#FFAAAAAA" :
           (formData.oyl_status === "tried_to_cancel" ? "#FFEE22AA" :
            (formData.ehs_status === "rejected" ? "#FF9900AA" : "#FFFFFF00")),
        }}
      >
        <Grid>
          <Box>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                color: "#000000",
              }}
            >
              Order {formData.orderId || ""} {formData.oyl_status === "order_canceled" ? " - CANCELED" : ""}
            </Typography>
          </Box>
        </Grid>

      </Box>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          backgroundColor: "#B5F4FE",
          padding: "2px",
          position: "relative",
          width: "100%",
        }}
      >
        <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
          SKUs
        </Typography>
      </Box>

      {/* SKU Chips Section */}
      <Grid>
        <Box sx={{ marginTop: 2 }}>
          {/* Display Chips */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginBottom: "15px",
            }}
          >
            {chipData.map((data, index) => (
              <Chip
                key={index}
                label={data}
                onDelete={() => handleDeleteChip(data)}
                sx={{ marginBottom: "5px" }}
              />
            ))}
          </Box>

          {/* Autocomplete for Adding SKUs */}
          <Autocomplete
            options={skuOptions}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                handleAddChip(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add SKU"
                placeholder="Select a SKU"
                variant="outlined"
              />
            )}
            sx={{ width: 300 }}
            disabled={loading} // Disable when loading
          />
        </Box>
      </Grid>
      <div className="mui-form-section-wrapper">
        <FormSection
          title="Order Details"
          fields={orderFields}
          formData={formData}
          handleChange={handleChange}
          loading={loading}
        />
      <div className="oyl-coupons-display">Coupons: [ {formData?.orderCoupons?.join(', ')} ]</div>
      </div>
    </Grid>
  );
};

export default OrderSection;
