/**
 * Logger utility for OwnYourLabs
 * Usage: lg("Some message")
 * Output: HH:MM:SS - [filename:line] Some message when ENABLE_TRACE=true, otherwise regular console.log
 */
const oyl_log = {
  // Store original console.log to prevent recursion
  originalLog: console.log.bind(console),

  /**
   * Get current time as HH:MM:SS
   */
  getTime() {
    const now = new Date();
    return `${String(now.getHours()).padStart(2, '0')}:${
      String(now.getMinutes()).padStart(2, '0')}:${
      String(now.getSeconds()).padStart(2, '0')}`;
  },

  /**
   * Format arguments to ensure compact JSON
   */
  formatArgs(args) {
    return args.map(arg => {
      if (arg === null) return 'null';
      if (arg === undefined) return 'undefined';
      if (typeof arg === 'object') {
        try {
          // Compact JSON - no whitespace
          return JSON.stringify(arg);
        } catch (e) {
          return arg.toString();
        }
      }
      return String(arg);
    });
  },

  /**
   * Log messages conditionally based on ENABLE_TRACE env variable
   * Defaults to disabled if ENABLE_TRACE is not found or not 'true'
   * @param {...any} args - Any number of arguments to log
   */
  lg(...args) {
    // Only enable if ENABLE_TRACE explicitly equals 'true'
    if (process.env.ENABLE_TRACE === 'true') {
      const err = new Error();
      const stackLine = err.stack?.split('\n')[2];
      const match = stackLine?.match(/[/\\]([^/\\]+?)\.(ts|tsx|js|jsx):(\d+):/);
      const location = match ? `[${match[1]}:${match[3]}]` : '[unknown]';
      const formattedArgs = this.formatArgs(args);
      this.originalLog(`${this.getTime()} - ${location}`, ...formattedArgs);
    } else {
      this.originalLog(...args);
    }
  }
};

// Export both the full object and the shorthand lg function
export const lg = oyl_log.lg.bind(oyl_log);
export default oyl_log;