
// Change from format '2024-09-19T23:12:02.000Z'
// to format '2024-09-19 7:12pm'
export function dateFromDBStringToPrettySimpleString(
  dateString:string,
  americanStyle:boolean = false,
  singleLine:boolean = false,
  excludeTime:boolean = false)
{
  const date = new Date(dateString);
  // Get components for formatting
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const toTheDay = americanStyle ? `${month}-${day}-${year}` : `${year}-${month}-${day}`;

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  // Convert to 12-hour format
  hours = hours % 12 || 12; // Adjust hours for 12-hour format
  if (excludeTime) return toTheDay;
  if (singleLine) return toTheDay + ` at ${hours}:${minutes}${ampm}`;
  return toTheDay + `\n${hours}:${minutes}${ampm}`;
}
