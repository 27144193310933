// This file contains generic, go-to functions for anaylzing, formatting
// and otherwise manipulating data from strings, JSON objects, arrays, etc.


// getFlatJSONDiff:
// Finds the diff between the Objects initialState and  and returns a new
//  Object with the values of currentState that differ.
//
// CONTRACT: initialState and currentState MUST HAVE IDENTICAL KEYS!!!
//           Both Objects are assumed to be flat in structure with strings
//           for keys, such that the can be cast to Record<string, any>
//
// If there are NO differences between initialState and currentState, then
//  this function returns null. If there ARE differences, then it returns a
//  new Object with ONLY those values from currentState that differ from
//  initialState, assigned to their corresponding keys.
export function getFlatJSONDiff(initialState, currentState) {
  const differences = {};
  for (const key in currentState) {
    if (currentState.hasOwnProperty(key) && initialState[key] !== currentState[key]) {
      differences[key] = currentState[key];
    }
  }
  //lg(' -- getFlatJSONDiff yields differences: ', differences);
  return Object.keys(differences).length === 0 ? null : differences;
}

/**
 * isolateNewArrayMembers returns all members of newArray that
 *  did not appear in oldArray. Uses === comparison to check.
 * @param {*} oldArray 
 * @param {*} newArray 
 */
export function isolateNewArrayMembers(oldArray, newArray) {
  if (!newArray || newArray.length < 0) return [];
  let retVal = [];
  for (let i = 0; i < newArray.length; ++i) {
    let node = newArray[i];
    if (!oldArray || oldArray.length < 0) {
      retVal.push(node);
    } else {
      let foundMatch = false;
      for (let j = 0; j < oldArray.length; ++j) {
        if (oldArray[j] === node) {
          foundMatch = true;
          j = oldArray.length;
        }
      }
      if (!foundMatch) {
        retVal.push(node);
      }
    }
  }
  return retVal;
}

// renameKeysWithMapping:
// Get a copy of data with its keys renamed as defined in keyMapping.
// Ignore all keyMapping keys not found in data, and vice-versa. If there
// are no overlapping key names between the two JSON Object/Records, then
// the return value will be an empty Object.
//
// CONTRACT: data and keyMapping are JSON Objects with key/value pairs,
//           where all keys are strings.
//
// Only root-level keys will be renamed.
//
// Ideal for use in handshake-data-translation. The idea is that the data
// Object has values that you need, but it can't be directly plugged into,
// e.g., a database row due to having the wrong key names. The keyMapping
// Object will tell you which of the keys to pluck out of the data (along
// with their values) and what the new key names should be for those vals
// in the resulting Object. Any keys that are missing will be ignored, as
// will any unexpected keys in data.
export function renameKeysWithMapping(data, keyMapping) {
  const renamedObject = {};

  for (const key in data) {
    if (keyMapping.hasOwnProperty(key)) {
      renamedObject[keyMapping[key]] = data[key];
    }
  }

  return renamedObject;
}

export function getSkusFromOylSkusNamed(stringArray) {
  let skus = [];
  for (const key in stringArray) {
    const str = stringArray[key];
    const sku = (str.indexOf(' - ') > 0) ? str.split(' - ')[0] : str;
    skus.push(sku);
  }
  return skus;
}