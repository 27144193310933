import React, { useState } from "react";
import { useUser, UserButton } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import OYLLogo from "../media/OYL-Logo-wide-short-small.png";

const pages: { name: string; path: string }[] = [
];

const CustomerStatusBar: React.FC = () => {
  const { user } = useUser();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const navigate = useNavigate(); // useNavigate hook for navigation

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (path: string) => {
    handleCloseNavMenu(); // Close the menu when navigating
    navigate(path); // Navigate to the selected path
  };

  const handleLogoClick = () => {
    window.location.href = "https://ownyourlabs.com";
  };

  // Extract adminRank from public metadata (string or number)
  const adminRank = user?.publicMetadata?.adminRank as
    | string
    | number
    | undefined;

  return (
    <AppBar position="static" sx={{ background: "linear-gradient(to left, #f4f4f4, #ffffff 75%)", color: "#000" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Linked Logo */}
          <Box
            sx={{ cursor: "pointer", display: "flex", alignItems: "center", marginRight: 2 }}
            onClick={handleLogoClick}
          >
            <img
              src={OYLLogo}
              alt="Own Your Labs Logo"
              style={{ height: "40px", objectFit: "contain" }} // Adjust height to fit the bar
            />
          </Box>

          {/* For smaller screens, show the menu icon */}
          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => handleNavigate(page.path)}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          {/* For larger screens, show the navigation buttons */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleNavigate(page.path)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {/* Show admin rank if available */}
          {adminRank && (
            <Typography variant="body1" sx={{ marginRight: 2 }}>
              Admin Rank: {adminRank}
            </Typography>
          )}

          {/* Make the UserButton flush right */}
          <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
            <UserButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default CustomerStatusBar;
