// ViewCustomerDashboard.tsx
import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  FormControl,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  AlertTitle,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  Link,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ViewCustomerDashboard_fetch from "./ViewCustomerDashboard_fetch";
import dayjs from "dayjs";
import { downloadPdfReq, downloadPdfResults } from "../services/EHSServices";
import { getFlatJSONDiff } from "../../shared/utils/dataTools";
import SuperSearchService from "../services/SuperSearchService";
import { lg } from "../../shared/utils/oyl_log";

const superSearchService = new SuperSearchService();

export interface CustomerData {
  customerFirstName: string;
  customerLastName: string;
  customerMiddleName?: string | null;
  customerEmail: string;
  customerPhone: string;
  customerBirthDate: string;
  customerGender: string;
  customerAddress1: string;
  customerAddress2?: string;
  customerCity: string;
  customerState: string;
  customerZip: string;
  customerId: string;
}

export interface OrderHistoryItem {
  orderId: string;
  ehsId: string;
  date: string;
  tests: string[];
  status: string;
}

// Styles
const tableRowStyle = {
  borderBottom: "1px solid #e0e0e0",
  transition: "background-color 0.2s ease",
};

const orderTableCellStyle = {
  padding: "12px 16px",
};

const orderTableStatusCellStyle = {
  padding: "12px 16px",
  cursor: "pointer",
};

const orderTableHeaderCellStyle = {
  padding: "12px 16px",
  textAlign: "left" as const,
};

const ViewCustomerDashboard: React.FC = () => {
  const { customerData, orderHistory, loading, error } = ViewCustomerDashboard_fetch();
  const [searchParams] = useSearchParams();
  const [verificationError, setVerificationError] = React.useState<string | null>(null);
  const [verificationSuccess, setVerificationSuccess] = React.useState(false);
  const [customerFormData, setCustomerFormData] = useState<CustomerData | null>(null);
  const [initialCustomerData, setInitialCustomerData] = useState<CustomerData | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  
  useEffect(() => {
    const verifyOrder = async () => {
      const orderId = searchParams.get('order');
      const token = searchParams.get('token');
      
      if (orderId && token) {
        try {
          const response = await fetch(`/api/verify-order?order=${orderId}&token=${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            const errorData = await response.json();
            setVerificationError(errorData.error || 'Verification failed');
          } else {
            setVerificationSuccess(true);
            // After successful verification, remove the query parameters
            window.history.replaceState({}, '', '/customer');
          }
        } catch (error) {
          setVerificationError('An error occurred during verification');
          console.error('Error during verification:', error);
        }
      }
    };

    verifyOrder();
  }, [searchParams]);

  // Initialize customerFormData and initialCustomerData when customerData loads
  useEffect(() => {
    if (customerData) {
      setCustomerFormData(customerData);
      setInitialCustomerData(customerData);
    }
  }, [customerData]);

  useEffect(() => {
    updateSaveButtonState();
  }, [customerFormData]);

  const handleChange = (field: keyof CustomerData, value: any) => {
    setCustomerFormData((prev: CustomerData | null) =>
      prev ? { ...prev, [field]: value } : null
    );
  };

  const handleCommitChanges = async () => {
    if (!customerFormData || !initialCustomerData) return;
  
    setIsSaveDisabled(true);
    try {
      const afterChanges = getFlatJSONDiff(initialCustomerData, customerFormData);
      if (!afterChanges) {
        console.error('ERROR: Tried to update DB, but no diff found in data');
        return;
      }
  
      const beforeChanges = getFlatJSONDiff(customerFormData, initialCustomerData) || {};
  
      const metaData = {
        user_is_admin: false,
        user_id: null,
        edit_note: "",
        oyl_id_customer: initialCustomerData.customerId,
        oyl_id_order: (orderHistory && orderHistory.length > 0) ? orderHistory[0].oylId : "-1",
        oyl_id_billing: "-1" // Since this is customer-only update
      };
  
      await superSearchService.executeSuperUpdate(
        metaData,
        beforeChanges,
        afterChanges
      );
  
    } catch (error) {
      console.error('Error: Failed to update from super view: ', error);
      return;
    } finally {
      setIsEditing(false);
    }
  
    setInitialCustomerData((prevData) => ({ ...prevData, ...customerFormData }));
  };

  const resetCustomerData = () => {
    if (initialCustomerData !== null) {
      setCustomerFormData((prevData) => ({ ...prevData, ...initialCustomerData }));
      setIsEditing(false);
    }
  }

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  function updateSaveButtonState() {
    if (!initialCustomerData) {
      setIsSaveDisabled(true);
    } else {
      const isDataChanged = JSON.stringify(customerFormData) !== JSON.stringify(initialCustomerData);
      setIsSaveDisabled(!isDataChanged);
    }
  }

  const handleStatusClick = async (order: OrderHistoryItem) => {
    if (order) {
      switch (order.status) {
        case "approved":
          downloadPdfReq(order.ehsId); // Download approved PDF
          break;
        case "results_released":
          downloadPdfResults(order.ehsId); // Download results PDF
          break;
        default:
          // Navigate to FAQ page
          window.location.href = `https://ownyourlabs.com/status`;
          break;
      }
    } else {
      lg("ERROR: clicked status, but order did not come through the callback");
    }
  };

  function getOrderStatusPrettyLine1(order:any):string {
    if (order.status.startsWith('pending')) {
      return "Your order is awaiting approval."
    }
    switch(order.status) {
      case "approved":
        return "Your lab order is approved.";
      case "results_released":
        return "Your results are available.";
      case "cancelled":
        return "Order canceled."
      default:
        return order.status;
    }
  }

  function getOrderStatusPrettyLine2(order:any):string {
    switch(order.status) {
      case "approved":
        return "Download lab order"
      case "results_released":
        return "Download results";
      default:
        return "What does this mean?";
    }
  }

  function orderStatusLinksToFAQ(order:any):boolean {
    switch(order.status) {
      case "approved":
        return false;
      case "results_released":
        return false;
      default:
        return true;
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Loading your dashboard...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Box>
    );
  }

  if (!customerData) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="warning">
          <AlertTitle>No Data Available</AlertTitle>
          We couldn't find your customer information. Please ensure you're signed in with the correct account.
        </Alert>
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 3 }}>
        {verificationError && (
          <Alert severity="error" onClose={() => setVerificationError(null)}>
            <AlertTitle>Verification Error</AlertTitle>
            {verificationError}
          </Alert>
        )}

        {/* Customer Information Section */}
        <Card>
          <CardContent sx= {{
            "& .MuiInputBase-root" : {
              color: "#000",
            }
          }}>
            {/* Top row box with label, undo button and edit button */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1.25em" }}>

              {/* Top row label */}
              <Typography variant="h5">Customer Information</Typography>

              {/* Container to group buttons together, so that the reset button doesn't float in the middle */}
              <Box sx={{ display: "flex", gap: "0.5em" }}>

                {/* Reset Button */}
                <IconButton
                  onClick={resetCustomerData}
                  disabled={isSaveDisabled}
                  color="primary"
                  aria-label="Reset Changes"
                >
                  <UndoIcon />
                </IconButton>

                {/* Edit Button */}
                <IconButton
                  onClick={toggleEditing}
                  disabled={!isSaveDisabled}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
              gap: 2,
              pointerEvents: isEditing ? "auto" : "none !important", // Disable interactions
              "& .MuiInputLabel-root": {
                color: "#000",
                pointerEvents: "none !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: isEditing ? "1 px solid #000" : "none !important",
              },
              "& .MuiOutlinedInput-input": {
                paddingLeft: "1.5em",
                paddingTop: "0.6em",
                paddingBottom: "0.6em",
              },
            }}>
              <TextField
                label="First Name"
                value={customerFormData?.customerFirstName || ""}
                onChange={(e) => handleChange("customerFirstName", e.target.value)}
              />
              <TextField
                label="Middle Name"
                value={customerFormData?.customerMiddleName || ""}
                onChange={(e) => handleChange("customerMiddleName", e.target.value)}
              />
              <TextField
                label="Last Name"
                value={customerFormData?.customerLastName || ""}
                onChange={(e) => handleChange("customerLastName", e.target.value)}
              />
              <TextField
                label="Email"
                value={customerFormData?.customerEmail || ""}
                onChange={(e) => handleChange("customerEmail", e.target.value)}
              />
              <TextField
                label="Phone"
                value={customerFormData?.customerPhone || ""}
                onChange={(e) => handleChange("customerPhone", e.target.value)}
              />
              <DatePicker
                label="Birth Date"
                value={customerFormData?.customerBirthDate ? dayjs(customerFormData.customerBirthDate) : null}
                onChange={(newValue) =>
                  handleChange("customerBirthDate", newValue ? newValue.format("YYYY-MM-DD") : null)
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    display: isEditing ? "block" : "none",
                  }
                }}
                slotProps={{ textField: { fullWidth: true } }}
              />
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiSelect-select": {
                    paddingLeft: "1.5em",
                    paddingTop: "0.6em",
                    paddingBottom: "0.6em",
                  },
                  "& .MuiSelect-root": {
                    color: "#000",
                    cursor: isEditing ? "pointer" : "default", // Adjust cursor for the "disabled" state
                  },
                  "& .MuiSelect-icon": {
                    display: isEditing ? "block" : "none", // Hide dropdown arrow for "disabled" state
                  },
                }}
              >
                {/* Properly Linked InputLabel */}
                <InputLabel id="gender-label">Biological Sex for Lab Purposes</InputLabel>

                {/* Select with manual disabling */}
                <Select
                  labelId="gender-label" // Links InputLabel to Select
                  value={customerFormData?.customerGender || ""}
                  onChange={(e: SelectChangeEvent) => handleChange("customerGender", e.target.value)}
                  displayEmpty
                  label="Biological Sex for Lab Purposes" // Required for outline notch
                  tabIndex={isEditing ? 0 : -1} // Remove focus/tab navigation when not editable
                >
                  <MenuItem value="" disabled>
                    Select Sex
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Box>

          </CardContent>
        </Card>

        {/* Save Changes Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCommitChanges}
          disabled={isSaveDisabled}
        >
          Save Changes
        </Button>

        {/* Order History Section */}
        <Card>
          <CardContent>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Order History
            </Typography>
            <Box sx={{ overflowX: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={orderTableHeaderCellStyle}>Date</th>
                    <th style={orderTableHeaderCellStyle}>Tests</th>
                    <th style={orderTableHeaderCellStyle}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orderHistory.map((order) => (
                    <tr key={order.orderId} style={tableRowStyle}>
                      <td style={orderTableCellStyle}>
                        {new Date(order.date).toLocaleDateString()}
                      </td>
                      <td style={orderTableCellStyle}>{order.tests.join(", ")}</td>
                      <td
                        style={orderTableStatusCellStyle}
                        onMouseOver={(e) => {
                          (e.currentTarget as HTMLElement).style.backgroundColor = "#f5f5f5";
                        }}
                        onMouseOut={(e) => {
                          (e.currentTarget as HTMLElement).style.backgroundColor = "";
                        }}
                        onClick={() => handleStatusClick(order)}
                      >
                        <Typography sx={{ width: "13em" }}>{getOrderStatusPrettyLine1(order)}</Typography>
                        { orderStatusLinksToFAQ(order) ?
                          ( <Link
                              href = "https://ownyourlabs.com/status"
                              target = "_blank"
                               rel = "noopener"
                            >
                              {getOrderStatusPrettyLine2(order)}
                            </Link>) :
                          ( <Link>{getOrderStatusPrettyLine2(order)}</Link>)
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </LocalizationProvider>
  );
};

export default ViewCustomerDashboard;
