// BillingFormSection.tsx

import React from "react";
import FormSection, { FormField } from "./FormSection"; // Adjust the path if necessary

interface BillingFormSectionProps {
  formData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}

const billingFields: FormField[] = [
  { label: "Billing First Name", name: "billingFirstName" },
  { label: "Billing Last Name", name: "billingLastName" },
  { label: "Billing Middle Name", name: "billingMiddleName" },
  { label: "Billing Email", name: "billingEmail" },
  { label: "Billing Phone", name: "billingPhone" },
  { label: "Billing Address 1", name: "billingAddress1" },
  { label: "Billing Address 2", name: "billingAddress2" },
  { label: "Billing City", name: "billingCity" },
  { label: "Billing State", name: "billingState" },
  { label: "Billing Zip", name: "billingZip" },
];

const BillingFormSection: React.FC<BillingFormSectionProps> = ({
  formData,
  handleChange,
  loading,
}) => {
  return (
    <FormSection
      title="Billing"
      fields={billingFields}
      formData={formData}
      handleChange={handleChange}
      loading={loading}
    />
  );
};

export default BillingFormSection;
